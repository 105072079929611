import * as Sentry from '@sentry/react';

import reportWebVitals, { sendToAnalytics } from './reportWebVitals';

import App from './App';
import React from 'react';
import { createBrowserHistory } from 'history';
import { createRoot } from 'react-dom/client';

// Create Custom Sentry Route component

const history = createBrowserHistory();

// import * as serviceWorker from './serviceWorker';

Sentry.init({
  dsn: 'https://eccdede1ef8e4f8da4d6a7c4e86cab3c@o879936.ingest.sentry.io/5835343',

  integrations: [
    Sentry.reactRouterV5BrowserTracingIntegration({ history }),
    Sentry.browserProfilingIntegration(),
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
    Sentry.captureConsoleIntegration(),
    Sentry.httpClientIntegration(),
    Sentry.sessionTimingIntegration(),
  ],
  environment: import.meta.env.PROD ? 'production' : 'development',

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  enabled: import.meta.env.PROD,
  release: 'ifancy@' + APP_VERSION,
  profilesSampleRate: 1.0,
  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.01,
  replaysOnErrorSampleRate: 0.1,
});

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();

reportWebVitals(sendToAnalytics);
